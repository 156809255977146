import axios from 'axios'

import {ElLoading, ElMessage, ElMessageBox} from 'element-plus'

import Global from '../Global.js'

import {businessGet} from '../api/business.js'

import html2canvas from 'html2canvas'

import {ElNotification} from 'element-plus'

import router from '../router/index.js'

export function utilsUploadFile(file, module, objName) {
    return new Promise(resolve => {
        var newFormData = new FormData();
        newFormData.append("file", file);
        newFormData.append("module", module);
        let loadingInstance = ElLoading.service({
            lock: true,
            text: '正在上传文件',
        });
        axios({
            method: "post",
            url: process.env.VUE_APP_API_URL + '/admin/v1/upload',
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: false,
            data: newFormData
        }).then(res => {
            loadingInstance.close();
            if (res.data.status == 200) {
                let msg = res.data.data;
                if (objName) {
                    msg.objName = objName
                }
                resolve(msg)
            }
            else {
                ElMessage({
                    showClose: true,
                    message: file.name + '，该文件上传失败',
                    type: 'warning'
                });
                resolve(null)
            }
        });
    })
}

export function utilsGetOptionsList(val) {
    let optionsObj = Global[val];
    let newArray = [];
    for (let k in optionsObj) {
        let a = {
            value: k,
            label: optionsObj[k]
        }
        newArray.push(a);
    }
    return newArray
}

export function utilsGetAuthority(authorityStatus) {
    let cmdsList = [];
    for (let k in authorityStatus) {
        let item = authorityStatus[k];
        let a = [item.url, item.method];
        cmdsList.push(a);
    }
    let url = '/admin/v1/auth/status?auth_list=' + JSON.stringify(cmdsList);
    businessGet(url).then(res => {
        if (res.data.status == 200) {
            let num = 0;
            for (let k in authorityStatus) {
                authorityStatus[k].show = res.data.data[num];
                num += 1
            }
        }
        else {
            ElMessage({
                showClose: true,
                message: res.data.message,
                type: 'warning'
            });
        }
    })
}

export function utilsExportExcel(url, msg) {
    let confirmMsg = '';
    if (msg) {
        confirmMsg = msg
    }
    else {
        confirmMsg = '此操作将按照搜索条件导出全部数据(数据量较大时请前往 系统配置-下载中心 下载), 是否继续?'
    }
    ElMessageBox.confirm(confirmMsg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    }).then(() => {
        businessGet(url).then(res => {
            if (res.data.status == 200) {
                if (res.data.data.file_path) {
                    window.open(res.data.data.file_path)
                }
                else {
                    ElMessage({
                        showClose: true,
                        message: res.data.message,
                        type: 'success'
                    });
                }
            }
            else {
                ElMessage({
                    showClose: true,
                    message: res.data.message,
                    type: 'warning'
                });
            }
        })
    }).catch(() => {

    })
}

export function utilsDownImg(val, fileName) {
    // this.$refs.imageTofile,文件名
    const opts = {
        useCORS: true,
        backgroundColor: null,
        dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
        scale: 4 //按比例增加分辨率
    }
    html2canvas(val, opts).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        let aLink = document.createElement('a');
        let blob = base64ToBlob(url); //new Blob([content]);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
    })

    function base64ToBlob(code) {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], {type: contentType});
    }
}

export function utilsNotificationJump(val, url) {
    ElNotification({
        title: '成功',
        message: val,
        type: 'success',
        duration: 0,
        onClick: function () {
            router.push(url)
        }
    })
}

export function utilsIdcardHide(val) {
    return val.substring(0,6)+'****'+val.substring(val.length-4)
}

export function utilsDX(money) {
    //汉字的数字
    var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
    //基本单位
    var cnIntRadice = new Array('', '拾', '佰', '仟');
    //对应整数部分扩展单位
    var cnIntUnits = new Array('', '万', '亿', '兆');
    //对应小数部分单位
    var cnDecUnits = new Array('角', '分', '毫', '厘');
    //整数金额时后面跟的字符
    var cnInteger = '整';
    //整型完以后的单位
    var cnIntLast = '元';
    //最大处理的数字
    var maxNum = 999999999999999.9999;
    //金额整数部分
    var integerNum;
    //金额小数部分
    var decimalNum;
    //输出的中文金额字符串
    var chineseStr = '';
    //分离金额后用的数组，预定义
    var parts;
    // 传入的参数为空情况
    if (money == '') {
        return '';
    }
    money = parseFloat(money)
    if (money >= maxNum) {
        return ''
    }
    // 传入的参数为0情况
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr
    }
    // 转为字符串
    money = money.toString();
    // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
    if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = ''
    } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    //转换整数部分
    if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length
        for (let i = 0; i < IntLen; i++) {
            let n = integerNum.substr(i, 1);
            let p = IntLen - i - 1;
            let q = p / 4;
            let m = p % 4;
            if (n == '0') {
                zeroCount++;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0]
                }
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        // 最后+ 元
        chineseStr += cnIntLast;
    }
    // 转换小数部分
    if (decimalNum != '') {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            let n = decimalNum.substr(i, 1);
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i]
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == '') {
        chineseStr += cnInteger;
    }

    return chineseStr
}
